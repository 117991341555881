<template>
  <div>
    <div class="logo-wrapper main__logo__title">
      <img :src="logo" />
    </div>
    <p class="card-text mb-25">{{ tenantData.address }},</p>

    <p class="card-text mb-25">{{ tenantData.city }}</p>
    <p class="card-text mb-25">{{ tenantData.country }}</p>
  </div>
</template>

<script>
export default {
  name: "Logo",

  data() {
    return {
      tenantData: [],
      userData: localStorage.getItem("userData"),
    };
  },

  computed: {
    logo() {
      const userData = localStorage.getItem("userData");
      if (userData) {
        const tenantLogo = JSON.parse(userData).tenantLogo;
        return "data:image/jpeg;base64," + tenantLogo;
      } else {
        // Replace with default logo if user data is not found
        return `../../assets/images/logo/logo.svg`;
      }
    },
  },

  mounted() {
    this.tenantData = JSON.parse(localStorage.getItem("userData"));
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.logo-wrapper img {
  width: 180px;
  height: 180px;
}
</style>
