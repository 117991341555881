<template>
    <div>
        <div class="d-flex justify-content-center mb-1">
            <b-spinner variant="success" span="Spinning" block v-if="loadingSpinner"
                style="width: 3rem; height: 3rem"></b-spinner>
        </div>

        <section class="invoice-preview-wrapper" v-if="!loadingSpinner">
            <b-row class="invoice-preview">
                <!-- Col: Left (Invoice Container) -->
                <b-col cols="12" xl="9" md="8">
                    <b-card no-body class="invoice-preview-card" id="invoice-preview-card"
                        style="display: block; width: 100%">
                        <b-card-body class="invoice-padding pb-0">
                            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
                                <!-- Header: Left Content -->
                                <Logo />

                                <!-- Header: Right Content -->
                                <div class="invoice-number-date mt-md-0 mt-2">
                                    <div class="d-flex align-items-center justify-content-md-end mb-1">
                                            <h4>Purchase Order
                                                #{{ purchaseorderId.orderNumber }}</h4>
                                      
                                    </div>
                                    <div class="d-flex align-items-center my-2">
                                        <span class="title"> Issued Date: </span>
                                        <flat-pickr v-model="purchaseorderId.issueDate"
                                            class="form-control invoice-edit-input" disabled />
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <span class="title"> Due Date: </span>
                                        <flat-pickr v-model="purchaseorderId.dueDate"
                                            class="form-control invoice-edit-input" disabled />
                                    </div>
                                </div>
                            </div>
                        </b-card-body>

                        <!-- Spacer -->
                        <hr class="invoice-spacing" />

                        <!-- Invoice Client & Payment Details -->
                        <b-card-body class="invoice-padding pt-0">
                            <b-row class="invoice-spacing">
                                <!-- Col: Invoice To -->
                                <b-col cols="12" xl="6" class="p-0">
                                    <h6 class="mb-2">Send To:</h6>
                                    <!-- Selected Client -->
                                    <div class="mt-1">
                                        <b-card-text class="mb-25">
                                            {{ supplierId.name }}
                                        </b-card-text>
                                        <b-card-text class="mb-25">
                                            {{ supplierId.email }}
                                        </b-card-text>
                                        <b-card-text class="mb-25">
                                            {{ supplierId.address }}
                                        </b-card-text>
                                        <b-card-text class="mb-25">
                                            {{ supplierId.phoneNumber }}
                                        </b-card-text>
                                        <b-card-text class="mb-0">
                                            {{ supplierId.contactPerson }}
                                        </b-card-text>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-card-body>

                        <!-- Invoice Description: Table -->
                        <b-table responsive :fields="fields" :items="purchaseorderId.purchaseOrderItems">
                            <template #cell(itemId)="data">
                                {{ getItemName(data.item.itemId) }}
                            </template>
                            <template #cell(cost)="data"> Rs {{ data.item.cost.toFixed(2) }} </template>
                            <template #cell(price)="data">
                                Rs {{ (data.item.cost * data.item.quantity).toFixed(2) }}
                            </template>
                        </b-table>

                        <!-- Invoice Description: Total -->
                        <b-card-body class="invoice-padding pb-0">
                            <b-row>
                                <!-- Col: Sales Persion -->
                                <b-col cols="12" md="6" class="mt-md-0 mt-3" order="2" order-md="1">
                                    <b-card-text class="mb-0">
                                        <span class="font-weight-bold">Salesperson :</span>
                                        <span class="ml-75">{{ purchaseorderId.salesPersonId }} </span>
                                    </b-card-text>
                                </b-col>

                                <!-- Col: Total -->
                                <b-col cols="12" md="6" class="mt-md-6 d-flex justify-content-end" order="1" order-md="2">
                                    <div class="invoice-total-wrapper">

                                        <hr class="my-50" />
                                        <div class="invoice-total-item">
                                            <p class="invoice-total-title">Total:</p>
                                            <p class="invoice-total-amount">
                                                Rs {{ purchaseorderId.total.toFixed(2) }}
                                            </p>
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-card-body>

                        <!-- Spacer -->
                        <hr class="invoice-spacing" />

                        <!-- Note -->
                        <b-card-body class="invoice-padding pt-0">
                            <span class="font-weight-bold">Note: </span>
                            <span>{{ purchaseorderId.notes }}</span>
                        </b-card-body>
                    </b-card>

                </b-col>

                <!-- Right Col: Card -->
                <b-col cols="12" md="4" xl="3" class="invoice-actions">
                    <b-card>
                        <!-- Button: DOwnload -->

                        <!-- Button: Print -->
                        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" class="mb-75" block
                            @click="printQuotation">
                            Print / Download
                        </b-button>
                    </b-card>
                </b-col>
            </b-row>
        </section>
    </div>
</template>
  
<script>
import {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTable,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    VBToggle,
    BLink,
    BFormSelect,
    BSpinner,
} from "bootstrap-vue";
import Logo from "@core/layouts/components/Logo.vue";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";


import { mapActions, mapGetters } from "vuex";

export default {
    directives: {
        Ripple,
        "b-toggle": VBToggle,
    },
    components: {
        flatPickr,
        BRow,
        BCol,
        BTable,
        BCard,
        BCardBody,
        BButton,
        BCardText,
        BForm,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BInputGroupPrepend,
        BFormTextarea,
        BFormCheckbox,
        BPopover,
        VBToggle,
        BFormSelect,
        Logo,
        BSpinner,
        BTable,
        BLink,
    },
    data() {
        return {
            id: this.$route.params.id,
            loadingSpinner: false,
            leadClientId: "",
            salesPersonId: "",
            dueDate: "",
            subTotal: "",
            discount: "",
            tax: "",
            total: "",
            notes: "",
            status: "",
            paymentMethod: "",
            quotationItems: {
                id: "",
                purchaseorderId: "",
                itemId: "",
                quantity: "",
                price: "",
                cost: "",
                isActive: "",
            },

            fields: [
                { key: "id", span: "ID", thClass: "d-none", tdClass: "d-none" }, //thClass and tdClass used to hide fields
                {
                    key: "purchaseorderId",
                    span: "QUOTATION_ID",
                    thClass: "d-none",
                    tdClass: "d-none",
                },
                { key: "itemId", span: "Item Name" },
                { key: "cost", span: "Unit Price (Rs)" },
                { key: "quantity", span: "QUANTITY" },

                { key: "price", span: "Total Price (Rs)" },
            ],
        };
    },
    computed: {
        ...mapGetters("purchaseOrderModule", {
            purchaseorderId: "purchaseorderId",
            loading: "loading",
        }),
        ...mapGetters("supplierModule", {
            supplierId: "supplierId",
            loading: "loading",
        }),
        ...mapGetters("salesPersonModule", {
            SalesPersonList: "salesperson",
            loading: "loading",
        }),
        ...mapGetters("productModule", {
            productList: "product",
            loading: "loading",
        }),
        ...mapGetters("rawmaterialModule", {
            materials: "rawmaterials",
            loading: "loading",
        }),
    },
    methods: {
        printQuotation() {
            window.print();
        },
        getItemName(id) {
            const product = this.productList.find((x) => x.id == id);
            if (product && product.name) {
                return product.name;
            } else {
                const material = this.materials.find((x) => x.id == id);
                if (material && material.name) {
                    console.log(material);
                    return material.name;
                }
            }
            return '';
        },
        ...mapActions("supplierModule", ["getSupplierByIDAction"]),
        ...mapActions("salesPersonModule", ["getSalesPersonListAction"]),
        ...mapActions("purchaseOrderModule", ["getPurchaseOrdersByIDAction"]),
        ...mapActions("productModule", ["getProductListAction"]),
        ...mapActions("rawmaterialModule", ["getRawMaterialListAction"]),

        downloadPDF() {
            var opt = {
                margin: [0, 0],
                filename: "quotation.pdf",
                image: { type: "jpeg", quality: 1 },
                html2canvas: { scale: 2, allowTaint: true },
                jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
            };
            var element = document.getElementById("invoice-preview-card");
            var elementHtml = element.outerHTML;
            html2pdf().from(elementHtml).set(opt).save();
        },
    },


    async mounted() {
        this.loadingSpinner = true; // Set to true when the component is mounted
        setTimeout(() => {
            this.loadingSpinner = false; // Set to false after 4 seconds
        }, 6000);

        await this.getProductListAction();
        await this.getRawMaterialListAction();
        await this.getPurchaseOrdersByIDAction(this.id)
            .then(() => {
                console.log(this.purchaseorderId);
                this.getSupplierByIDAction(this.purchaseorderId.supplierId);
                this.getSalesPersonListAction().then(() => {
                    this.SalesPersonList.forEach((element) => {
                        if (element.id == this.purchaseorderId.salesPersonId) {
                            this.purchaseorderId.salesPersonId = element.firstName + " " + element.lastName;
                        }
                    });
                });
            })
            .catch((response) => {
                console.log(response);
            });
    },
};
</script>
  
<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
  
<style lang="scss">
@media print {
    a[href]:after {
        content: none !important;
    }

    html,
    body {
        height: 99% !important;
    }

    // Global Styles
    .invoice-date-title {
        color: black !important;
    }

    * {
        color: black !important;
    }

    body {
        background-color: transparent !important;
        font-size: 17.5px !important;
        color: black !important;
    }

    .flatpickr-input {
        font-size: 17.5px !important;
        color: black !important;

    }

    nav.header-navbar {
        display: none;
    }

    .main-menu {
        display: none;
    }

    .header-navbar-shadow {
        display: none !important;
    }

    .content.app-content {
        margin-left: 0;
        padding-top: 2rem !important;
    }

    footer.footer {
        display: none;
    }

    .card {
        background-color: transparent;
        box-shadow: none;
    }

    .customizer-toggle {
        display: none !important;
    }

    .content-header {
        display: none !important;
    }

    .invoice-edit-input {
        border: none;
    }

    // Invoice Specific Styles
    .invoice-preview-wrapper {
        .row.invoice-preview {
            .col-md-8 {
                max-width: 100%;
                flex-grow: 1;
            }

            .invoice-preview-card {
                .card-body:nth-of-type(2) {
                    .row {
                        >.col-12 {
                            max-width: 50% !important;
                        }

                        .col-12:nth-child(2) {
                            display: flex;
                            align-items: flex-start;
                            justify-content: flex-end;
                            margin-top: 0 !important;
                        }
                    }
                }
            }
        }

        // Action Right Col
        .invoice-actions {
            display: none;
        }
    }
}
</style>
  